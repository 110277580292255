















































































import Avatar from '@/modules/community/common/components/avatar.vue'
import { rewardStore } from '@/stores/rewardStore'
import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'
import { communityOwnerBoardController } from './owner-section-controller'

@Observer
@Component({
  components: {
    Avatar,
    notification: () => import('@/components/notification/notification.vue'),
  },
})
export default class extends Vue {
  controller = communityOwnerBoardController
  wallet = walletStore
  rewardStore = rewardStore

  get getUserAccountName() {
    return this.wallet.userProfile?.unique_id
  }
}
